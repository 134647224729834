import React, { useState } from 'react';
import { collection, addDoc } from 'firebase/firestore';
import { db } from './firebaseConfig';
import Logo from './assets/Albuddy.png';

const CollegePrepForm = () => {
  const initialFormData = {
    name: '',
    email: '',
    phone: '',
    role: 'Parent',
    parentHopes: '',
    parentBarriers: [], // Checkboxes stored as array
    otherBarriers: '', // Added this to handle the 'other' input value
    studentExcitement: '',
    studentGoals: '',
    studentMissing: [],
    futureVision: '',
    careerImpact: '',
    consultation: 'Yes', // Consultation saved as Yes or No
    timestamp: '', // Timestamp for each submission
  };

  const [formData, setFormData] = useState(initialFormData);
  const [submittedName, setSubmittedName] = useState('User'); // New state for the name after form submission
  const [formSubmitted, setFormSubmitted] = useState(false); // State for submission notification
  const [formSection, setFormSection] = useState('Parent');

  const handleInputChange = (e) => {
    const { name, value, type, checked } = e.target;

    if (type === 'checkbox') {
      setFormData((prevState) => ({
        ...prevState,
        parentBarriers: checked
          ? [...prevState.parentBarriers, value] // Add value to array if checked
          : prevState.parentBarriers.filter((item) => item !== value), // Remove value if unchecked
      }));
    } else {
      setFormData({
        ...formData,
        [name]: value,
      });

      if (name === 'role') {
        setFormSection(value);
      }
    }
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    // Combine the "otherBarriers" input with the checked barriers
    const updatedFormData = {
      ...formData,
      parentBarriers: [...formData.parentBarriers, formData.otherBarriers].filter(Boolean), // Ensure no empty values
      timestamp: new Date().toISOString(), // Adding timestamp
    };

    try {
      // Save the submitted name for the alert message
      setSubmittedName(formData.name || 'User');

      await addDoc(collection(db, 'CollegePrepFormResponsesUSA'), updatedFormData);
      console.log(formData.name);

      // Show success message
      setFormSubmitted(true);
      window.scrollTo({ top: 0, behavior: 'smooth' });
      setTimeout(() => setFormSubmitted(false), 10000);

      // Clear the form data and reset checkboxes
      setFormData(initialFormData);
    } catch (error) {
      console.error('Error submitting form:', error);
    }
  };

  const isParent = formSection === 'Parent';

  return (
    <div className="max-w-3xl mx-auto bg-white p-8 shadow-lg rounded-lg space-y-8">
      {/* Success message for form submission */}
      {formSubmitted && (
        <div
          className="p-4 mb-4 text-green-700 bg-green-100 rounded-lg text-center"
        >
          Thank you, {submittedName}! Your feedback has been submitted successfully.
        </div>
      )}

      {/* Header with logo */}
      <div className="text-center mb-8">
        <img
          src={Logo}
          alt="College Prep Header"
          className="mx-auto w-full h-40 object-contain rounded-lg"
        />
        <h1 className="text-4xl font-semibold text-[#4A90E2]">Feedback Form</h1>
        <p className="text-lg text-gray-500">
          Hey, it's Marcus here! Thank you so much for your interest in Albuddy. I'm excited to hear your feedback and stay connected—your input means a lot, and you might just see some of your ideas in action!
        </p>
      </div>

      {/* Disclaimer */}
      <p className="text-center text-sm text-gray-500">
        All information is kept safe and confidential. Please do not include sensitive information such as passwords or private security details.
      </p>

      <form onSubmit={handleSubmit} className="space-y-12">
        {/* Basic Info */}
        <div className="p-8 bg-[#F0F8FF] shadow-md rounded-md space-y-6">
          <h2 className="text-2xl font-semibold text-[#4A90E2]">Basic Information<span className="text-red-600">*</span></h2>
          <div className="grid grid-cols-1 md:grid-cols-2 gap-8">
            <div>
              <label className="block text-lg font-medium text-gray-600 mb-2">Your Name (Parent or Student)</label>
              <input
                name="name"
                type="text"
                value={formData.name}
                onChange={handleInputChange}
                className="block w-full border-gray-300 rounded-lg shadow-sm focus:ring-[#4A90E2] focus:border-[#4A90E2] p-4 text-lg"
                placeholder="Enter your name"
                required
              />
            </div>

            <div>
              <label className="block text-lg font-medium text-gray-600 mb-2">Email Address</label>
              <input
                name="email"
                type="email"
                value={formData.email}
                onChange={handleInputChange}
                className="block w-full border-gray-300 rounded-lg shadow-sm focus:ring-[#4A90E2] focus:border-[#4A90E2] p-4 text-lg"
                placeholder="Enter your email"
                required
              />
            </div>

            <div>
              <label className="block text-lg font-medium text-gray-600 mb-2">Phone Number</label>
              <input
                name="phone"
                type="tel"
                value={formData.phone}
                onChange={handleInputChange}
                className="block w-full border-gray-300 rounded-lg shadow-sm focus:ring-[#4A90E2] focus:border-[#4A90E2] p-4 text-lg"
                placeholder="Enter your phone number"
                required
              />
            </div>

            <div>
              <label className="block text-lg font-medium text-gray-600 mb-2">Are you a Parent or a Student?</label>
              <select
                name="role"
                value={formData.role}
                onChange={handleInputChange}
                className="block w-full border-gray-300 rounded-lg shadow-sm focus:ring-[#4A90E2] focus:border-[#4A90E2] p-4 text-lg"
              >
                <option value="Parent">Parent</option>
                <option value="Student">Student</option>
              </select>
            </div>
          </div>
        </div>

        {isParent && (
          <div className="p-8 bg-[#F0F8FF] shadow-md rounded-md space-y-6">
            <div>
              <label className="block text-lg font-medium text-gray-600 mb-2">
                Long-term hopes for your child in tech or computer science?<span className="text-red-600">*</span>
              </label>
              <textarea
                name="parentHopes"
                value={formData.parentHopes}
                onChange={handleInputChange}
                className="block w-full border-gray-300 rounded-lg shadow-sm focus:ring-[#4A90E2] focus:border-[#4A90E2] p-4 text-lg"
                placeholder="Share your hopes"
                required={isParent}
              />
            </div>

            <div>
              <h2 className="text-2xl font-semibold text-[#4A90E2]">Biggest Barriers for Your Child in Tech/CS<span className="text-red-600">*</span></h2>
              <p>(Check all that apply)</p>

              <div className="flex flex-col space-y-4">
                <label className="flex items-center space-x-3">
                  <input
                    type="checkbox"
                    name="parentBarriers"
                    value="Lack of real-world project experience"
                    onChange={handleInputChange}
                    checked={formData.parentBarriers.includes("Lack of real-world project experience")}
                    className="form-checkbox h-6 w-6 text-[#4A90E2] rounded focus:ring focus:ring-[#4A90E2]"
                  />
                  <span className="text-lg text-gray-700">Lack of real-world project experience</span>
                </label>

                <label className="flex items-center space-x-3">
                  <input
                    type="checkbox"
                    name="parentBarriers"
                    value="Uncertainty about career paths in CS"
                    onChange={handleInputChange}
                    checked={formData.parentBarriers.includes("Uncertainty about career paths in CS")}
                    className="form-checkbox h-6 w-6 text-[#4A90E2] rounded focus:ring focus:ring-[#4A90E2]"
                  />
                  <span className="text-lg text-gray-700">Uncertainty about career paths in CS</span>
                </label>

                <input
                  name="otherBarriers" // This handles the input for 'Other' barriers
                  type="text"
                  placeholder="Other (please specify)"
                  value={formData.otherBarriers}
                  onChange={handleInputChange}
                  className="block w-2/3 border-gray-300 rounded-lg shadow-sm focus:ring-[#4A90E2] focus:border-[#4A90E2] p-4 text-lg mt-4"
                />
              </div>
            </div>
          </div>
        )}

        {/* Student Section */}
        {!isParent && (
          <div className="p-8 bg-[#F0F8FF] shadow-md rounded-md space-y-6">
            <div>
              <label className="block text-lg font-medium text-gray-600 mb-2">
                What excites you most about working in Computer Science?<span className="text-red-600">*</span>
              </label>
              <textarea
                name="studentExcitement"
                value={formData.studentExcitement}
                onChange={handleInputChange}
                className="block w-full border-gray-300 rounded-lg shadow-sm focus:ring-[#4A90E2] focus:border-[#4A90E2] p-4 text-lg"
                placeholder="Share your excitement"
                required={!isParent}
              />
            </div>

            <div>
              <label className="block text-lg font-medium text-gray-600 mb-2">
                What do you hope to achieve with the coding skills?<span className="text-red-600">*</span>
              </label>
              <textarea
                name="studentGoals"
                value={formData.studentGoals}
                onChange={handleInputChange}
                className="block w-full border-gray-300 rounded-lg shadow-sm focus:ring-[#4A90E2] focus:border-[#4A90E2] p-4 text-lg"
                placeholder="Share your goals"
                required={!isParent}
              />
            </div>
          </div>
        )}

        <div className="p-8 bg-[#F0F8FF] shadow-md rounded-md space-y-6">
          <div>
            <label className="block text-lg font-medium text-gray-600 mb-2">
              {isParent
                ? 'How do you envision your child thriving after gaining real-world experience in Computer Science?'
                : 'How do you envision thriving after gaining real-world experience in Computer Science?'}
              <span className="text-red-600">*</span>
            </label>
            <textarea
              name="futureVision"
              value={formData.futureVision}
              onChange={handleInputChange}
              className="block w-full border-gray-300 rounded-lg shadow-sm focus:ring-[#4A90E2] focus:border-[#4A90E2] p-4 text-lg"
              placeholder={isParent ? 'Share your vision for your child' : 'Share your vision'}
              required
            />
          </div>

          <div>
            <label className="block text-lg font-medium text-gray-600 mb-2">
              {isParent
                ? 'What kind of impact do you want your child to make in the tech world for their career?'
                : 'What kind of impact do you want to make in the tech world for your career?'}
              <span className="text-red-600">*</span>
            </label>
            <textarea
              name="careerImpact"
              value={formData.careerImpact}
              onChange={handleInputChange}
              className="block w-full border-gray-300 rounded-lg shadow-sm focus:ring-[#4A90E2] focus:border-[#4A90E2] p-4 text-lg"
              placeholder={isParent ? 'Share your desired impact for your child' : 'Share your desired impact'}
              required
            />
          </div>
        </div>

        <div className="p-8 bg-[#F0F8FF] shadow-md rounded-md space-y-6">
          <div>
            <label className="block text-lg font-medium text-gray-600 mb-2">
              Would you like to schedule a free consultation?<span className="text-red-600">*</span>
            </label>
            <select
              name="consultation"
              value={formData.consultation}
              onChange={handleInputChange}
              className="block w-full border-gray-300 rounded-lg shadow-sm focus:ring-[#4A90E2] focus:border-[#4A90E2] p-4 text-lg"
              required
            >
              <option value="Yes">Yes</option>
              <option value="No">No</option>
            </select>
          </div>

          <button
            type="submit"
            className="w-full bg-[#4A90E2] text-white rounded-lg p-5 text-lg hover:bg-[#357ABD] focus:outline-none focus:ring focus:ring-[#357ABD]"
          >
            Submit
          </button>
        </div>

        {/* Contact Info */}
        <div className="text-center mt-10 bg-[#F0F8FF] p-6 rounded-lg shadow-md">
          <p className="text-lg text-gray-600">
            Feel free to reach out!{' '}
            <a href="https://albuddy.com" className="text-[#4A90E2] underline">
              albuddy.com
            </a>{' '}
            or text{' '}
            <a href="tel:+1234567890" className="text-[#4A90E2] underline">
              +1 (646) 887 - 7772
            </a>.
          </p>
        </div>
      </form>
    </div>
  );
};

export default CollegePrepForm;
